// Flatly 5.3.3
// Bootswatch

// Variables

[data-bs-theme="light"] {
  --bs-body-color: var(--bs-black);
  --bs-body-color-rgb: 0, 0, 0;
  --bs-body-bg: #068dc4;
  --bs-body-bg-rgb: 6, 141, 196;
  --bs-tertiary-bg: #097bae;
  --bs-tertiary-bg-rgb: 9, 123, 174;
  --bs-secondary-color: rgba(77, 77, 77, 0.75);
}

[data-bs-theme="dark"] {
  --bs-body-color: var(--bs-white);
  --bs-body-color-rgb: #fff;
  --bs-body-bg: #044462;
  --bs-body-bg-rgb: 4, 68, 98;

  --bs-tertiary-bg: #033349;
  --bs-tertiary-bg-rgb: 3, 51, 73;

  --bs-secondary-color: rgba(77, 77, 77, 0.75);


}



////////////////
/* [data-bs-theme="light"] {
  --bs-body-color: var(--bs-white);
  --bs-body-color-rgb: #fff;
  --bs-body-bg: #0977aa;
  --bs-body-bg-rgb: 9, 119, 170;


  --bs-tertiary-bg: #065579;
  --bs-tertiary-bg-rgb: 6, 85, 121;


} */
/// 


$web-font-path: "https://fonts.googleapis.com/css?family=PT+Sans:400,700|Cinzel|Droid+Serif|Yesteryear" !default;

@if $web-font-path {

  @import url("#{$web-font-path}");

}

// Navs

.pagination {
  a:hover {
    text-decoration: none;
  }
}

// Indicators

.badge {
  &.bg-light {
    color: $dark;
  }
}

.alert {
  color: $white;
  border: none;

  a,
  .alert-link {
    color: $white;
    text-decoration: underline;
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      @if $enable-gradients {
        background: $value linear-gradient(180deg, mix($body-bg, $value, 15%), $value) repeat-x;
      }

      @else {
        background-color: $value;
      }
    }
  }

  &-light {

    &,
    a,
    .alert-link {
      color: $body-color;
    }
  }
}

// Containers

.modal,
.toast,
.offcanvas {
  .btn-close {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$black}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>"));
  }
}



body {
  padding-top: 70px;
  font-family: 'PT Sans', sans-serif;
  color: var(--bs-body-color);
}

blockquote {

  border-left: none;
  padding: 40px 20px 40px 20px;
  line-height: 1.5em;

}

blockquote p {
  font-family: 'Times New Roman', Times, serif;
  padding: 0.8rem;
  font-style: italic;
  font-size: 1.4rem;
}

blockquote::before {
  color: #F9C446;
  font-family: 'Font Awesome 6 Free';
  font: var(--fa-font-solid);
  content: '\f10d';
}

blockquote::after {
  color: #F9C446;
  font-family: 'Font Awesome 6 Free';
  font: var(--fa-font-solid);
  content: '\f10e';
  float: right;
}

.nav-link {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin-right: 0.7rem;
}

.navbar-expand-lg {
  border: none;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25), 0px -1px 0px rgba(0, 0, 0, 0.1) inset;
  transition: all 0.4s ease;
}

.navbar-nav>li>a,
.navbar-nav>li>a:visited {
  color: #FFF;
}

.navbar-nav>li>a:hover {
  color: #F9C446
}

.navbar-nav>li>a.active {
  color: #FFF !important;
  border-bottom: 2px solid #F9C446;
}

.navbar-nav>li>a.active:hover {
  color: #000 !important;
  border-bottom: 2px solid #F9C446;
}

.fancybox-image {
  padding: 4px;
  background-color: #fff;
  width: 100%;
}

@media all and (min-width: 992px) {

  .havepar ul {

    margin-top: 0rem;
    margin-bottom: 1.75rem;
    margin-left: 2.75rem;
    list-style: none;

  }

  .havepar li::before {

    color: #F9C446;
    font-family: 'Font Awesome 6 Free';
    font: var(--fa-font-solid);
    content: '\f00c';
    padding-right: 1.0rem;

  }

/*   .havepar ul li strong::before {
    color: #F9C446;
    font-family: 'Font Awesome 6 Free';
    font: var(--fa-font-solid);
    content: '\f00c';
    padding-right: 1.0rem;
  } */

  .navbar-expand-lg .dropdown-menu {
    position: absolute;
    top: 93%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: var(--bs-dark);
    text-align: left;
    list-style: none;
    background-color: var(--bs-white);
    background-clip: padding-box;
    border: 0px solid rgba(0, 0, 0, .15);
    border-radius: .0rem;
    -webkit-box-shadow: 4px 7px 12px -5px rgba(0, 0, 0, 0.40);
    -moz-box-shadow: 4px 7px 12px -5px rgba(0, 0, 0, 0.40);
    box-shadow: 4px 7px 12px -5px rgba(0, 0, 0, 0.40);
  }

  .navbar-expand-lg .dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    color: var(--bs-dark);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .navbar-expand-lg .dropdown-item:hover {

    background-color: var(--bs-tertiary-bg);
    color: var(--bs-white);

  }

  .navbar-expand-lg .active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg);
  }

  .navbar .nav-link .dropdown {
    display: none;
  }

  .navbar .nav-item:hover .nav-link {
    background-color: var(--bs-white);
    color: var(--bs-black);
  }

  .navbar .nav-item:hover .dropdown-menu {
    border-radius: 0;
    margin-left: 0;
    display: block;
  }

  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
    font-size: inherit;
  }

}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background-color: #fff;
}
#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 8px solid #293966;
  border-top-color: #fff;
  border-bottom-color: #775dbd;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: rotate-center 0.6s linear infinite;
  animation: rotate-center 0.6s linear infinite;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media only screen and (max-device-width: 1366px) {
  .parallax {
    background-attachment: scroll;
  }
}